/* eslint-disable @typescript-eslint/no-unused-vars */
export const cleanObject = (object: Object) => {
    return Object.fromEntries(
        Object.entries(object).filter(
            ([_, v]) => ![undefined, null, ""].includes(v)
        )
    );
};

export const isObjectEmpty = (object: Object) => {
    if (!object) return true;
    return Object.keys(object).length === 0 && object.constructor === Object;
};
