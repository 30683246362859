import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Menu: ComponentStyleConfig = {
    parts: ["button", "list", "item"],
    baseStyle: {
        button: {},
        list: {},
        item: {},
    },

    variants: {
        blue: {
            button: {
                bg: "detail.850",

                color: "black",
                fontSize: "xxs",
                fontWeight: "semibold",
                p: "2",
                rounded: "md",
            },
            list: {
                bg: "detail.850",

                color: "black",
                fontSize: "xxs",
                fontWeight: "semibold",
                p: "2",
                rounded: "md",
            },
            item: {
                display: "flex",
                alignItems: "baseline",
                flexGrow: 1,
            },
        },
    },

    defaultProps: {
        button: {
            bg: "none",
            color: "white",
            fontWeight: "normal",
            pt: "12px",
            mt: "-1px",
            w: "190px",
            _active: { bg: "white", textColor: "blackAlpha.600" },
            _focus: { outline: "none" },
            _hover: { bg: "none" },
        },
        list: {
            bg: "detail.800",
            border: "1px solid",
            borderColor: "detail.500",
            color: "black",
            fontWeight: "semibold",
            p: "2",
            rounded: "md",
        },
    },
};

export default Menu;
