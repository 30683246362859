export const stringToCamel = (string: string) => {
    return string.replace(/([-_][a-z])/gi, $1 => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });
};

export const stringToSnakeCase = (str: string) =>
    str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const keysToCamelCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToCamel(k)] = object[k];
    });

    return newObject;
};

export const keysToSnakeCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToSnakeCase(k)] = object[k];
    });

    return newObject;
};
