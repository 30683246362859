import { drawerAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const service = {
    header: {
        color: "primary.700",
        fontSize: "3xl",
        borderBottomWidth: "1px",
    },
};

const documents = {
    header: {
        color: "primary.700",
        fontSize: "3xl",
        borderBottomWidth: "1px",
    },
    dialog: {
        zIndex: "0",
    },
};

const drawerTheme = defineMultiStyleConfig({
    variants: {
        service,
        documents,
    },
});

export default drawerTheme;
