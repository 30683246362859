import { LoadingScreen } from "@components/LoadingScreen";
import { getBootstrapByTenant } from "@lib/api/theming";
import { useMemo, useReducer } from "react";
import { useQuery } from "@tanstack/react-query";
import { TenantThemeContext } from "./TenantThemeContext";
import { tenantThemeReducer } from "./tenantThemeReducer";
import { useRouter } from "next/router";

export interface TenantThemeState {
    id: string;
    name: string;
    logo: string;
    authImage?: string;
}

const THEME_INITIAL_STATE: TenantThemeState = {
    id: "1",
    name: "",
    logo: "",
    authImage: "",
};

export const TenantThemeProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        tenantThemeReducer,
        THEME_INITIAL_STATE
    );

    const { query, isReady } = useRouter();

    const isTenant = useMemo(() => !!query?.site, [query?.site]);

    const { data, isFetching } = useQuery<TenantThemeState>(
        ["tenantTheme"],
        () => getBootstrapByTenant(),
        { refetchOnWindowFocus: false, enabled: isTenant }
    );

    const setTenantTheme = (tenant: any) => {
        dispatch({ type: `[THEME] - Set Tenant Theme`, payload: tenant });
    };

    return (
        <TenantThemeContext.Provider
            value={{
                ...state,
                ...data,
                // Methods
                setTenantTheme,
            }}
        >
            {isFetching || !isReady ? <LoadingScreen /> : children}
        </TenantThemeContext.Provider>
    );
};
