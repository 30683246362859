import { getConnector } from "@lib/axios";

const apiVersion = process.env.NEXT_PUBLIC_API_VERSION;

const revalidateToken = process.env.NEXT_SECRET_REVALIDATE;

/**
 * Get bootstrap data by tenant on server side
 * @param tenantId
 */

export const getBootstrapByTenant = async () => {
    const headers = { "X-TOKEN": revalidateToken };

    const { data } = await getConnector(
        `/hermes/api/${apiVersion}/bootstrap`,
        {},
        headers
    );

    return data;
};
