// example theme.js
import { extendTheme } from "@chakra-ui/react";
import styles from "./styles";
import Button from "./components/button";
import FormLabel from "./components/formLabel";
import Table from "./components/table";
import Modal from "./components/modal";
import Badge from "./components/badge";
import Progress from "./components/Progress";
import Avatar from "./components/avatar";
import Tabs from "./components/tabs";
import Input from "./components/input";
import Stat from "./components/stat";
import Select from "./components/select";
import Menu from "./components/menu";
import Tag from "./components/tag";
import Alert from "./components/alert";
import Drawer from "./components/drawer";
import Card from "./components/card";
import Pulse from "./components/pulse";
import NumberInput from "./components/numberInput";
import Editable from "./components/editable";
import Link from "./components/link";
import Checkbox from "./components/checkbox";

const breakpoints = {
    sm: "40em",
    md: "48em",
    lg: "64em",
    xl: "80em",
    "2xl": "120em",
    "3xl": "140em",
};

export const colors = {
    primary: {
        "50": "#E8EEFD",
        "100": "#BED2FF",
        "200": "#94B2F5",
        "300": "#6994F1",
        "400": "#3F77EE",
        "500": "#1559EA",
        "600": "#1147BB",
        "700": "#0D358C",
        "800": "#09235D",
        "900": "#04122F",
        1000: "#2463EB1A",
        1100: "#1C2351",
        1200: "#382F7A",
        1300: "#2463EB",
    },
    cyan: {
        "50": "#EBF6F9",
        "100": "#C8E6EF",
        "200": "#A4D6E4",
        "300": "#81C6DA",
        "400": "#5EB6CF",
        "500": "#3AA6C5",
        "600": "#2F849D",
        "700": "#236376",
        "800": "#17424F",
        "900": "#0C2127",
    },
    alert: {
        50: "#DD425A26",
        100: "#DD425A",
        150: "#4CAF50",
        200: "#4CAF5026",
        250: "#4CAF501A",
        300: "#EBF5FB",
        350: "#FFE7EA",
        400: "#FFF0E3",
    },
    background: {
        50: "#F4F9FF",
        100: "#CADFFF",
        150: "#060E40",
        200: "#F3F2F7",
        250: "#C1CDE3",
        300: "#F5F8FF",
        350: "#EFEFEF",
        400: "#0F1C71",
        450: "#E1ECFF",
        500: "#F0F9FF",
        550: "#ECF5FF",
        600: "#DDEDFC",
    },
    text: {
        50: "#6E6B7B",
        100: "#7367F0",
        150: "#3855B3",
        200: "#979797",
        300: "#B5B5B5",
        400: "#0A2525",
        500: "#B9B9C3",
        600: "#97A3B7",
        700: "#9E9E9E",
        800: "#3C557A",
        900: "#5D5D5E",
        1000: "#565758",
        1100: "#474747",
        1200: "#9A9A9A",
        1300: "#4F4F4F",
        1400: "#142479",
        1500: "#023E86",
        1600: "#8C8C92",
        1650: "#BCC4F8",
        1700: "#7F8A96",
    },
    detail: {
        50: "#EBE9F1",
        100: "#F5F5F5",
        500: "#1F64FF",
        600: "#757575",
        700: "#B9B9C3",
        750: "#5997E1",
        800: "#F5F8FF",
        850: "#E2EFFF",
        900: "#F7F7F7",
        950: "#E2E8F0",
        1000: "#E3F0FF",
        1100: "#E8EAF1",
    },
    accent: {
        50: "#4CAF50",
        100: "#DD425A",
        150: "#3C557A",
        200: "#AEC7FF26",
        250: "#FFA000",
        300: "#43B5F4",
        350: "#A02929",
        400: "#D0ECFC",
        450: "#18ACFF",
        500: "#C1DDEC",
        550: "#EBF8FFCC",
        600: "#CED8EB",
        650: "#DCECFF",
        700: "#90BFF8",
        750: "#EBF1FD",
        800: "#105DFB",
        850: "#E3ECFF",
        900: "#1F73B7",
        950: "#1d5d90",
        1000: "#467EC2",
        1100: "#B4DEFF",
        1200: "#2196F3",
        1300: "#EDF4FF",
    },
    badge: {
        50: "#43B5F41F",
        100: "#2463EB1A",
        150: "#FFA0001A",
        200: "#D900261A",
        250: "#EE8329",
    },
    bgBlue: {
        100: "#DDEDFC",
        200: "#DBF8FB",
        300: "#00A3B7",
    },
    todo: { 500: "#020246", 600: "#1AC057" },
};

const overrides = {
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },

    breakpoints,

    colors,

    styles,

    components: {
        Button,
        FormLabel,
        Table,
        Modal,
        Badge,
        Progress,
        Avatar,
        Tabs,
        Input,
        Stat,
        Select,
        Menu,
        Tag,
        Alert,
        Drawer,
        Card,
        Pulse,
        NumberInput,
        Editable,
        Link,
        Checkbox,
    },

    fonts: {
        body: "Inter, sans-serif",
        heading: "Inter, sans-serif",
    },

    fontSizes: {
        xxs: "10px",
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
    },

    fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    },

    lineHeights: {
        body: 1.5,
        heading: 1.2,
    },

    sizes: {
        screen: "100vh",
    },
};

export default extendTheme(overrides);
