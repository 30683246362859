const FormLabel = {
    baseStyle: {
        display: "flex",
        fontWeight: "400",
        color: "gray.700",
        fontSize: "xs",
        marginBottom: "2",
    },
};

export default FormLabel;
