import { createContext } from "react";

interface ThemeContextProps {
    id: string;
    name: string;
    logo: string;
    authImage?: string;
    // Methods
    setTenantTheme: (tenant: any) => void;
}

export const TenantThemeContext = createContext({} as ThemeContextProps);
